import processMarkdown from '../../../libs/marked'

const url = ''
const footerContent = {
  content: processMarkdown(`
  `),
  url,
}

export default footerContent
